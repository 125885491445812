
import { UPDATE_FORM_FIELD, SAVE_FORM_DATA } from '../actions/formActions';
import { UPDATE_LINE_ITEM_FIELD, ADD_LINE_ITEM, REMOVE_LINE_ITEM, I_UPDATE_LINE_ITEM_FIELD } from '../actions/linesItems';
import { UPDATE_ORIGIN_ADDRESS, DESTINATIONS_ORIGIN_ADDRESS } from '../actions/originDestinationAddress';
import { UPDATE_USER_DETAILS } from '../actions/userdetails';

const initialState = {
  customerName: '',
  transactionNumber: '',
  customerCode: '',
  transactionCode: '',
  transactionDate: '',
  customerAccNumber:'',

  productCode: '',
  quantity: '',
  unitPrice: '',
  totalPrice: '',
  destinationAddress: '',
  destinationCountry: '',
  destinationState: '',
  destinationZipCode: '',

  lineItems: [{
    productCode: '',
    quantity: '',
    unitPrice: '',
    totalPrice: '',
    destinationAddress: '',
    destinationCountry: '',
    destinationState: '',
    destinationZipCode: ''
  }],

  olineOne: '',
  oLineTwo: '',
  oLineThree: '',
  oCountry: '',
  oState: '',
  oCity: '',
  oZipCode: '',
  oLatLog: '',
  oStreeNum:'',

  dlineOne: '',
  dLineTwo: '',
  dLineThree: '',
  dCountry: '',
  dState: '',
  dCity: '',
  dZipCode: '',
  dLatLog: '',
  dStreeNum:'',

  userDetails:{}

};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case ADD_LINE_ITEM:
      return {
        ...state,
        lineItems: [
          ...state.lineItems,
          {
            productCode: '',
            quantity: '',
            unitPrice: '',
            totalPrice: '',
            destinationAddress: '',
            destinationCountry: '',
            destinationState: '',
            destinationZipCode: ''
          },
        ],
      };

    case REMOVE_LINE_ITEM:
      return {
        ...state,
        lineItems: state.lineItems.filter((_, index) => index !== action.payload),
      };

    case UPDATE_LINE_ITEM_FIELD:
      return {
        ...state,
        lineItems: state.lineItems.map((item, index) =>
          index === action.payload.index
            ? { ...item, [action.payload.field]: action.payload.value }
            : item
        ),
      };

    case I_UPDATE_LINE_ITEM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case UPDATE_ORIGIN_ADDRESS:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case DESTINATIONS_ORIGIN_ADDRESS:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

      case UPDATE_USER_DETAILS:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    case SAVE_FORM_DATA:
      // Logic to save data to DB or API would be here
      console.log('Form Data Saved:', state);
      return state;

    default:
      return state;
  }
};

export default formReducer;
