import React, { useState } from 'react';
import Stack from '@mui/system/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DestinationAddress from './DestinationAddress';
import LineItems from './LineItems';
import TransactionDetails from './TransactionDetails';
import { Button } from '@mui/material';
import DynamicPanel from './DynamicPanel';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from 'react-redux';
import OriginAddress from './OriginAddress';
import AppUrl from '../utils/AppUrl';
import apiRequest from '../redux/apiRequest';
import { toast } from 'react-toastify';
import LoadingMask from '../utils/LoadingMask';
const TaxFilingForm = () => {

  const [addLine, setAddLine] = useState(false);
  const form = useSelector((state) => state.form);
  const [loadMask,setLoadMask]=React.useState(false)

  const onSaveBtnClick = async () => {
    console.log(form)

    let lineItems = [{
      "lnItmNum": "1",
      "lnItmId": new Date().getTime().toString(),
      "stcity": form.oCity,
        "stcountry": form.dCountry,
        "stpstlcd": form.dZipCode,
        "ststnamenum": form.dlineOne + form.dLineTwo + form.dLineThree,
        "ststnum": form.dStreeNum,
        "ststateprov": form.dState,
        "goodsrvcd": form.productCode,
        "grossamt": form.unitPrice,
        "orgcd": "204",
        "qnty": form.quantity,
        "sfcity": form.oCity,
        "sfcountry": form.oCountry,
        "sfpstlcd": form.oZipCode,
        "sfstnamenum": form.olineOne + form.oLineTwo + form.oLineThree,
        "sfstnum": form.oStreetNum,
        "sfstateprov": form.oState,
        "uniqueId": new Date().getTime().toString()
    }]

    form.lineItems.map((item, index) => (
      lineItems.push({
        "lnItmNum": index+2,
        "lnItmId": new Date().getTime().toString(),
        "stcity": form.oCity,
        "stcountry": form.dCountry,
        "stpstlcd": form.dZipCode,
        "ststnamenum": form.dlineOne + form.dLineTwo + form.dLineThree,
        "ststnum": form.dStreeNum,
        "ststateprov": form.dState,
        "goodsrvcd": item.productCode,
        "grossamt": item.unitPrice,
        "orgcd": "204",
        "qnty": item.quantity,
        "sfcity": form.oCity,
        "sfcountry": form.oCountry,
        "sfpstlcd": form.oZipCode,
        "sfstnamenum": form.olineOne + form.oLineTwo + form.oLineThree,
        "sfstnum": form.oStreetNum,
        "sfstateprov": form.oState,
        "uniqueId": new Date().getTime().toString()
      })
    ))

    let params = {
      "customername": form.customerName,
      "trnDocNum": "1198",
      "currency": form.currencyDetails,
      "docdt": form.transactionDate.$d.toISOString(),
      "transactionSrc": "001",
      "lines": lineItems,
      "shipToAccountName": form.customerName,
      "shipToAccountNumber": form.customerAccNumber,
      "registration_Id": form.userDetails.id
    }
    setLoadMask(true)
    const data = await apiRequest(AppUrl.taxestimateusa, 'POST', params);
    setLoadMask(false)
    if(data && data.status==true){
      toast.info(data.message);
    }else if(data && data.message && data.message.includes("Response") && data.status==false){
      let responseString=data.message;      
      const jsonPart = responseString.split('Response: ')[1];
      const responseJson = JSON.parse(jsonPart);
      toast.error(responseJson.errorMessage)
    }else{
      toast.error("Please try after sometime.");
    }
  }

  return (
    <>
      <LoadingMask show={loadMask} message="Please Wait..." />
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <TransactionDetails />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <div style={{ float: "right" }}>
              <Button variant="contained" startIcon={<AddCircleIcon />} color="primary" onClick={() => setAddLine(true)} >
                Line Items
              </Button>
            </div>
            <LineItems />
          </CardContent>
        </Card>


        <DynamicPanel addItems={addLine} resetItems={() => setAddLine(false)} />

        <Card>
          <CardContent>
            <OriginAddress title={"Origin Address"} />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <DestinationAddress title={"Destination Address ( Billing Address)"} />
          </CardContent>
        </Card>

      </Stack>

      <div style={{ float: "right", margin: 10, padding: 10 }}>
        <Button startIcon={<SaveIcon />} style={{ marginRight: 10 }} variant="contained" onClick={onSaveBtnClick}>Save</Button>
        <Button startIcon={<RotateLeftIcon />} variant="outlined">Reset</Button>
      </div>
    </>
  );
};

export default TaxFilingForm;
