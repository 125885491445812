import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RegisterUser from './components/RegisterUser';
import Login from './components/Login';
import TransactionsList from './components/TransanctionList';
import { CssBaseline, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ImportExportView from './components/ImportExport';
import Layout from './components/AppBarDrawer';
import TaxFilingForm from './components/TaxFilingForm';
import UserList from './components/UserList';
import EmailPasswordReset from './components/EmailPasswordReset';
import { ToastContainer } from 'react-toastify';
import DynamicPanel from './components/DynamicPanel';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  const dispatch = useDispatch();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#f5f5f5',
      },
      text: {
        primary: '#333',
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold',
      },
      body1: {
        fontSize: '1rem',
      },
    },
    spacing: 8,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
          containedPrimary: {
            backgroundColor: '#3f51b5',
            color: '#fff',
          },
        },
      },
    },
  });

  React.useEffect(()=>{
    

  },[isAuthenticated])

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    <Router>
      <CssBaseline />
      {isAuthenticated ?
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<TransactionsList />} />
            <Route path="/register" element={<RegisterUser />} />
            <Route path="/importexport" element={<ImportExportView />} />
            <Route path="/taxfiling" element={<TaxFilingForm />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/dynamicpanel" element={<DynamicPanel />} />
            <Route path="/reset-password/:token" element={<EmailPasswordReset/>} />
          </Routes>
        </Layout>
        :
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password/:token" element={<EmailPasswordReset/>} />
        </Routes>
      }
    </Router>
    </ThemeProvider>
  );
};

export default App;