import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import AppUrl from '../utils/AppUrl';
import apiRequest from '../redux/apiRequest';


const EmailPasswordReset = () => {
  const { token } = useParams(); // This will get the token from the URL
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const handleSubmit = async () => {
    if (!password) {
      toast.error('Password is required.');
      return;
    }

    if (!confirmpassword) {
      toast.error('Confirm Password is required.');
      return;
    }
    if (password !== confirmpassword) {
      toast.error('Password and Confirm Password is not same.');
      return
    }

    let params = { EmailId: email, password: confirmpassword, token: token };
    const data = await apiRequest(AppUrl.resetpassword, 'POST', params);
    console.log(data);
    toast.success('Password reset successful');
    navigate('/login');

  }

  const onHandlePassword = (e) => {
    const { name, value } = e.target;
    setPassword(value);
  }

  const onHandleConfirmPassword = (e) => {
    const { name, value } = e.target;
    setConfirmPassword(value);
  }

  useEffect(() => {
    console.log(token)
    console.log(email)
  }, [])

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!password) {
      toast.error('Password is required');
      return;
    }

    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Reset password failed');
      }

      toast.success('Password reset successful');
      navigate('/login');
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">

      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card variant="outlined">
          <CardContent>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                name="password"
                type="password"
                autoFocus
                onChange={onHandlePassword}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm Password"
                type="password"
                id="password"
                onChange={onHandleConfirmPassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => handleSubmit(e)}
              >
                Change Password
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default EmailPasswordReset;
