import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemButton, ListItemIcon, ListItemText, CssBaseline, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { useSelector } from 'react-redux';

import PeopleIcon from '@mui/icons-material/People';
const drawerWidth = 240;

const Layout = ({ children }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const form = useSelector((state) => state.form);
    const [isAdmin, setIsAdmin] = useState(false);

    React.useEffect(() => {

        if (form && form.userDetails && form.userDetails.emailId=="jay.singh@lnsinfusion.com") {
            setIsAdmin(true);
        }else{
            setIsAdmin(false);
        }

    }, [form])

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleListItemClick = (path) => {
        navigate(path);
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" style={{ zIndex: 1300 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Tax Dasboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                style={{ width: drawerWidth }}
                PaperProps={{ style: { width: drawerWidth } }}
            >
                <div>
                    <Toolbar />
                    <Divider />
                    <List>
                        <ListItemButton onClick={() => handleListItemClick('/home')}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>

                        <ListItemButton onClick={() => handleListItemClick('/taxfiling')}>
                            <ListItemIcon>
                                <AssignmentReturnIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tax Filing" />
                        </ListItemButton>

                        {/* <ListItemButton>
                            <ListItemIcon>
                                <ImportExportIcon />
                            </ListItemIcon>
                            <ListItemText primary="Import" onClick={() => handleListItemClick('/importexport')} />
                        </ListItemButton> */}

                        {isAdmin && <ListItemButton onClick={() => handleListItemClick('/register')}>
                            <ListItemIcon>
                                <PersonAddAltIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Users" />
                        </ListItemButton>
                        }

                        {isAdmin && <ListItemButton onClick={() => handleListItemClick('/userlist')}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users List" />
                        </ListItemButton>
                        }

                    </List>
                </div>
            </Drawer>
            <main style={{ flexGrow: 1, padding: '24px', marginTop: '64px' }}>
                {children}
            </main>
        </div>
    );
};

export default Layout;
