import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['your/action/type'],
        // Ignore these paths in the state
        ignoredPaths: ['your.state.path'],
        // Ignore specific non-serializable values
        isSerializable: (value) => value !== '[non-serializable value]'
      },
    })
});


export default store;
