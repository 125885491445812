import { combineReducers } from 'redux';
import exampleReducer from './slices/exampleSlice';
import authReducer from './slices/authSlice';
import formReducer from './reducers/formReducer';

const rootReducer = combineReducers({
  example: exampleReducer,
  auth: authReducer,
  form: formReducer
});

export default rootReducer;
