// LoadingMask.js

import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

// Styled Backdrop component
const StyledBackdrop = styled(Backdrop)({
  zIndex: 1300, // Ensures it overlays other elements
  color: '#fff', // Spinner color
  flexDirection: 'column',
});

const LoadingMask = ({ show, message = 'Loading...' }) => {
  return (
    <StyledBackdrop open={show}>
      <CircularProgress color="inherit" />
      {message && (
        <Typography variant="h6" style={{ marginTop: 16 }}>
          {message}
        </Typography>
      )}
    </StyledBackdrop>
  );
};

export default LoadingMask;
