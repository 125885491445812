
const baseUrl = () =>{
    if(window.location.hostname=="devtaxdashboard.lnsinfusion.com"){
        return "/api";
    }else{
        return "http://devtaxdashboard.lnsinfusion.com/api";
    }
}

const AppUrl ={
    validateLogin:baseUrl() + '/UsersControllers/Login',
    getUsers: baseUrl() + '/UsersControllers/getusers',
    userRegister:baseUrl () +'/UsersControllers/Registration',
    sendEmail:baseUrl () +'/Email/send',
    resetpassword:baseUrl () +'/UsersControllers/resetpassword',
    taxestimateusa:baseUrl()+'/v1/taxestimateusa',
    getTransaction:baseUrl()+'/v1/GetAllTaxEstimateUSA'

}



export default AppUrl;