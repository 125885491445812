import React, { useState } from 'react';
import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import apiRequest from '../redux/apiRequest';
import AppUrl from '../utils/AppUrl';
import LoadingMask from '../utils/LoadingMask';
import { toast } from 'react-toastify';

const RegisterUser = () => {

    const [loadMask,setLoadMask]=React.useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        contactNumber: '',
        address: '',
        companyName: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //setLoadMask(true)
        let params={
            firstName:formData.firstName,
            emailId:formData.email,
            password:formData.password,
            contactNumber:formData.contactNumber,
            address:formData.firstName,
            companyName:formData.companyName,
            lastName:formData.lastName,
            userName:formData.firstName+"_"+formData.lastName
        }   

        const data = await apiRequest(AppUrl.userRegister, 'POST',params);
        //setLoadMask(false);
        //toast.success("User Registerd successfully.");
        console.log(data);
    };

    const handleReset = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            contactNumber: '',
            address: '',
            companyName: '',
        });
    };

    return (
        <>
        <LoadingMask show={loadMask} message="Registering Users..." />
        <Container component="main" maxWidth="ml">
            <CssBaseline />
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            marginTop: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Register User
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoComplete="fname"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="lname"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="contactNumber"
                                        label="Contact Number"
                                        name="contactNumber"
                                        autoComplete="tel"
                                        value={formData.contactNumber}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="companyName"
                                        label="Company Name"
                                        name="companyName"
                                        autoComplete="organization"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="address"
                                        label="Address"
                                        name="address"
                                        autoComplete="street-address"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />

                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, marginRight: 2 }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="reset"
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Container>
        </>
    );
};

export default RegisterUser;
