import * as React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import AppUrl from '../utils/AppUrl';
import LoadingMask from '../utils/LoadingMask';
import apiRequest from '../redux/apiRequest';


const TransactionsList = () => {

  const [loadMask,setLoadMask]=React.useState(false)
  const [data,setData]=React.useState([]);

  React.useEffect(()=>{
    const loadTransList = async() =>{
      const res = await apiRequest(AppUrl.getTransaction, 'GET',{});
      let idData=res.sovosResponse.map((item,index)=>{
        item.sovosResponse_Id=new Date().getTime()+index;
        return item;
      })
      setData(idData);
    }
    loadTransList()
  },[])


 
  
  const columns = [
    { field: 'trnDocNum', headerName: 'Doc No', flex:1 },
    { field: 'txAmt', headerName: 'Tax Amout', flex:1},
    { field: 'sovosResponse_Id', headerName: 'Response ID', flex:1},
    { field: 'txwTrnDocId', headerName: 'Transaction ID', flex:1},
    { field: 'jurSumRslts', headerName: 'Summary Results', flex:1}
  ];

  const getRowId = (row) => `${row.sovosResponse_Id}-${row.trnDocNum}`;

  return (
    <>
      <Card variant="elevation">
        <CardContent>
          <Box marginBottom={2}>
            <Typography variant="h5" gutterBottom>
              Transanction List
            </Typography>
          </Box>
          <div style={{ height: '80%', width: '100%' }}>
            <DataGrid disableColumnSorting disableColumnMenu rows={data} columns={columns} getRowId={getRowId} />
          </div>
        </CardContent>
      </Card>
    </>

  );
}

export default TransactionsList;
