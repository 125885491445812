import React, { useState } from 'react';
import { TextField, Typography, Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateILineItemField } from '../redux/actions/linesItems';

const LineItems = (props) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateILineItemField(name, value));
  };

  return (
    <>


      <Typography variant="h6" gutterBottom>
        Line Items 1
      </Typography>

      <form>
        <Box>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Product Code"
                name="productCode"
                variant="outlined"
                value={form.productCode}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Quantity"
                name="quantity"
                variant="outlined"
                value={form.quantity}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Unit Price"
                name="unitPrice"
                variant="outlined"
                value={form.unitPrice}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Total Price"
                name="totalPrice"
                variant="outlined"
                value={form.totalPrice}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Destination Address "
                name="destinationAddress"
                variant="outlined"
                value={form.destinationAddress}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Destination Country"
                name="destinationCountry"
                variant="outlined"
                value={form.destinationCountry}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Destination State"
                name="destinationState"
                variant="outlined"
                value={form.destinationState}
               onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Destination ZipCode"
                name="destinationZipCode"
                variant="outlined"
                value={form.destinationZipCode}
               onChange={handleInputChange}
              />
            </Grid>

          </Grid>
        </Box>
      </form>


    </>
  );
};

export default LineItems;
