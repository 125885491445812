import React, { useState } from 'react';
import { Button, TextField, Box ,Container} from '@mui/material';

const ImportExportView  = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadClick = () => {
    if (selectedFile) {
      // Handle file upload logic here
      console.log('Uploading file:', selectedFile);
    }
  };

  const handleResetClick = () => {
    setSelectedFile(null);
    document.getElementById('file-input').value = '';
  };

  return (
    

    <Box display="flex" flexDirection="column" alignItems="left">
      <input
        accept="*/*"
        style={{ display: 'none' }}
        id="file-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="file-input">
        <Button variant="contained" component="span">
          Choose File
        </Button>
      </label>
      {selectedFile && (
        <TextField
          margin="normal"
          fullWidth
          variant="outlined"
          disabled
          value={selectedFile.name}
        />
      )}
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadClick}
          disabled={!selectedFile}
        >
          Upload
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleResetClick}
          sx={{ ml: 2 }}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default ImportExportView ;
