// src/App.js
import React, { useState,useEffect  } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AppUrl from '../utils/AppUrl';
import apiRequest from '../redux/apiRequest';


const UserList = () => {
    const [users, setUsers] = useState([]);

    const handleEdit = (id) => {
        // Handle edit logic here
        alert(`Edit user with ID: ${id}`);
    };

    const handleDelete = (id) => {
        setUsers(users.filter(user => user.id !== id));
    };

    const loadUsers= async () =>{
        const data = await apiRequest(AppUrl.getUsers, 'GET',{});
        setUsers(data.length>0?data:[]);
    }

    useEffect(() => {
        const fetchUsers = async () => {
          try {
            const data = await apiRequest(AppUrl.getUsers, 'GET');
            setUsers(data);
          } catch (err) {
            console.log(err.message);            
          }
        };
    
        fetchUsers();
      }, []);

    const columns = [
        { field: 'firstName', headerName: 'First Name',  flex: 1 },
        { field: 'lastName', headerName: 'Last Name',  flex: 1 },
        { field: 'emailId', headerName: 'Email Address',  flex: 1 },
        { field: 'contactNumber', headerName: 'Contact Number',  flex: 1 },
        { field: 'companyName', headerName: 'Company Name',  flex: 1 },
        { field: 'address', headerName: 'Address',  flex: 1 },
        { field: 'createdOn', headerName: 'Creation Date',  flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={() => handleEdit(params.id)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.id)}
                />,
            ],
        },
    ];

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">
                    User List
                </Typography>
                <DataGrid
                    disableColumnSorting
                    disableColumnMenu
                    rows={users}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </CardContent>
        </Card>
    );
}

export default UserList;
