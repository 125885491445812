
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';

export const updateFormField = (field, value) => {
  return {
    type: UPDATE_FORM_FIELD,
    payload: { field, value },
  };
};

export const saveFormData = () => {
  return {
    type: SAVE_FORM_DATA,
  };
};

export const saveLineItem = () =>{
  
}
