import React from 'react';
import { TextField, Container, Typography, Box, Grid, Paper } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField, saveFormData } from '../redux/actions/formActions';

const TransactionDetails = (props) => {
    const dispatch = useDispatch();
    const form = useSelector((state) => state.form);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateFormField(name, value));
    };
    const handleDateChange = (date) => {
        dispatch(updateFormField('transactionDate', date));
    };

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Transaction Details
            </Typography>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2.5} >
                        <TextField
                            fullWidth
                            label="Customer Name"
                            variant="outlined"
                            name="customerName"
                            value={form.customerName}
                            onChange={handleInputChange}
                            
                        />
                    </Grid>

                    <Grid item xs={12} sm={2} >
                        <TextField
                            fullWidth
                            label="Transaction Number"
                            variant="outlined"
                            name="transactionNumber"
                            value={form.transactionNumber}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={1.5} >
                        <TextField
                            fullWidth
                            label="Customer Code"
                            variant="outlined"
                            name="customerCode"
                            value={form.customerCode}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={1.5} >
                        <TextField
                            fullWidth
                            label="Transaction Code"
                            variant="outlined"
                            name="transactionCode"
                            value={form.transactionCode}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={1.5} >
                        <TextField
                            fullWidth
                            label="Currency Details"
                            variant="outlined"
                            name="currencyDetails"
                            value={form.currencyDetails}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2.5} >
                        <TextField
                            fullWidth
                            label="Account Number"
                            variant="outlined"
                            name="customerAccNumber"
                            value={form.customerAccNumber}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker 
                                label="Transaction Date"
                                name="transactionDate"
                                value={form.transactionDate?form.transactionDate:null}
                                onChange={handleDateChange} 
                                renderInput={(params) => <TextField {...params} />}/>
                                
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default TransactionDetails;
