
export const UPDATE_ORIGIN_ADDRESS = 'UPDATE_ORIGIN_ADDRESS';
export const DESTINATIONS_ORIGIN_ADDRESS = 'DESTINATIONS_ORIGIN_ADDRESS';

export const updateOriginAddress = (field, value) => {
  return {
    type: UPDATE_ORIGIN_ADDRESS,
    payload: { field, value },
  };
};

export const updatedestinationAddress = (field, value) => {
  return {
    type: DESTINATIONS_ORIGIN_ADDRESS,
    payload: { field, value },
  };
};
