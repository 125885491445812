
import React, { useState } from 'react';
import { Card, CardContent, IconButton, Typography, TextField, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addLineItem, removeLineItem, updateLineItemField } from '../redux/actions/linesItems';
const DynamicPanel = (props) => {

  const dispatch = useDispatch();
  const lineItems = useSelector((state) => state.form.lineItems);

  const [panels, setPanels] = useState([]);

  const addPanel = () => {
    setPanels([...panels, panels.length + 1]);
    dispatch(addLineItem());
  };


  React.useEffect(() => {
    if (props.addItems == true) {
      addPanel();
      props.resetItems();
    }
  }, [props.addItems])

  const removePanel = (id) => {
    setPanels(panels.filter(panel => panel !== id));
    dispatch(removeLineItem(id));
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    dispatch(updateLineItemField(index, name, value));
  };

  return (
    <>
      {lineItems.map((item, index) => (
        <Card key={index} sx={{ marginBottom: 2 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Line Item {index + 2}
              </Typography>
              <IconButton aria-label="close" onClick={() => removePanel(index)}>
                <CloseIcon />
              </IconButton>
            </div>
            <Box>
              <Grid container spacing={3} key={index} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Product Code"
                    name="productCode"
                    variant="outlined"
                    value={item.productCode}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    variant="outlined"
                    value={item.quantity}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Unit Price"
                    name="unitPrice"
                    variant="outlined"
                    value={item.unitPrice}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Total Price"
                    name="totalPrice"
                    variant="outlined"
                    value={item.totalPrice}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Destination Address "
                    name="destinationAddress"
                    variant="outlined"
                    value={item.destinationAddress}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Destination Country"
                    name="destinationCountry"
                    variant="outlined"
                    value={item.destinationCountry}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Destination State"
                    name="destinationState"
                    variant="outlined"
                    value={item.destinationstate}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Destination ZipCode"
                    name="destinationZipCode"
                    variant="outlined"
                    value={item.destinationZipCode}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Grid>

              </Grid>
            </Box>
          </CardContent>
        </Card>
      ))}


    </>
  )
}

export default DynamicPanel;
