import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updatedestinationAddress } from '../redux/actions/originDestinationAddress';

const DestinationAddress = (props) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updatedestinationAddress(name, value));
  }

  return (
    <>

      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 1"
            name="dlineOne"
            variant="outlined"
            value={form.dlineOne}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 2"
            name="dLineTwo"
            variant="outlined"
            value={form.dLineTwo}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 3"
            name="dLineThree"
            variant="outlined"
            value={form.dLineThree}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Street Num 3"
            name="dStreeNum"
            variant="outlined"
            value={form.dStreeNum}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="City"
            name="dCity"
            variant="outlined"
            value={form.dCity}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="State"
            name="dState"
            variant="outlined"
            value={form.dState}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            name="dCountry"
            label="Country"
            variant="outlined"
            value={form.dCountry}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="Zip Code"
            name="dZipCode"
            variant="outlined"
            value={form.dZipCode}
            onChange={handleInputChange}
          />
        </Grid>

      </Grid>
    </>
  );
};

export default DestinationAddress;
