import React, { useState } from 'react';
import { TextField, Button, Modal, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordReset = (props) => {
  const [email, setEmail] = useState('');
  
  

  const handleClose = () =>{

  }

  const style={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 4,
    p: 4,
  }

  return (
    <div>
      <Modal open={props.showForgot} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Reset Password
          </Typography>
         
            <TextField
              label="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button style={{ float: "right" }} variant="contained" color="primary" type="submit" onClick={()=>props.onClose()}>
              Reset Password
            </Button>
          
        </Box>
      </Modal>
    </div>
  );
};

export default PasswordReset;
