import React from 'react';
import { TextField, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateOriginAddress } from '../redux/actions/originDestinationAddress';

const OriginAddress = (props) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateOriginAddress(name, value));
  }

  return (
    <>

      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 1"
            name="olineOne"
            variant="outlined"
            value={form.olineOne}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 2"
            name="oLineTwo"
            variant="outlined"
            value={form.oLineTwo}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Line 3"
            name="oLineThree"
            variant="outlined"
            value={form.oLineThree}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={4}>
          <TextField
            fullWidth
            label="Street Num 3"
            name="oStreeNum"
            variant="outlined"
            value={form.oStreeNum}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="City"
            name="oCity"
            variant="outlined"
            value={form.oCity}
            onChange={handleInputChange}
          />
        </Grid>

        

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="State"
            name="oState"
            variant="outlined"
            value={form.oState}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            name="oCountry"
            label="Country"
            variant="outlined"
            value={form.oCountry}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <TextField
            fullWidth
            label="Zip Code"
            name="oZipCode"
            variant="outlined"
            value={form.oZipCode}
            onChange={handleInputChange}
          />
        </Grid>

      </Grid>
    </>
  );
};

export default OriginAddress;
