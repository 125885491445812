
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const UPDATE_LINE_ITEM_FIELD = 'UPDATE_LINE_ITEM_FIELD';
export const I_UPDATE_LINE_ITEM_FIELD= 'I_UPDATE_LINE_ITEM_FIELD';

export const addLineItem = () => {
  return {
    type: ADD_LINE_ITEM,
  };
};

export const removeLineItem = (index) => {
  return {
    type: REMOVE_LINE_ITEM,
    payload: index,
  };
};

export const updateLineItemField = (index, field, value) => {
  return {
    type: UPDATE_LINE_ITEM_FIELD,
    payload: { index, field, value },
  };
};

export const updateILineItemField = (field, value) => {
  return {
    type: I_UPDATE_LINE_ITEM_FIELD,
    payload: { field, value }
  };
};
