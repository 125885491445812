// apiService.js

/**
 * Make an API request using the Fetch API.
 *
 * @param {string} endpoint - The endpoint of the API (e.g., '/users').
 * @param {string} method - The HTTP method (GET, POST, etc.).
 * @param {Object} [data] - The request payload for POST, PUT, etc.
 * @param {Object} [headers] - Additional headers for the request.
 * @returns {Promise} - A promise that resolves to the response data.
 */
const apiRequest = async (endpoint, method = 'GET', data = null, headers = {}) => {
  try {
    const url = `${endpoint}`;
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };

    if (data && method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);

    // Check if response is okay
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Something went wrong!');
    }

    // Return response data
    return response.json();
  } catch (error) {
    // Log or handle errors
    console.error('API request error:', error);
    throw error;
  }
};

export default apiRequest;
