import React, { useState, useContext } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import { updateUserDetails } from '../redux/actions/userdetails';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ToastContainer,toast } from 'react-toastify';
import PasswordReset from './PasswordReset';
import AppUrl from '../utils/AppUrl';
import apiRequest from '../redux/apiRequest';
import LoadingMask from '../utils/LoadingMask';

const theme = createTheme();

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadMask,setLoadMask]=React.useState(false)

    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [showForgotPassword ,setShowForgotPassword]=useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLoginData({
            ...loginData,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (loginData.email != "" && loginData.password != "") {
            setLoadMask(true)
            let params={
                emailId:loginData.email,
                password:loginData.password
            };
            const data = await apiRequest(AppUrl.validateLogin, 'POST',params);
            setLoadMask(false)
            if(data && data.message){
                toast.error("Email address or Password is incorrect.");
                return
            }            
            dispatch(login(loginData))
            dispatch(updateUserDetails('userDetails',data))
            navigate('/home')
           
        } else {
            toast.error("Email address or Password is missing.");
            return
        }        
    };

    return (
        <>
        <LoadingMask show={loadMask} message="Validating Users..." />
        {showForgotPassword && <PasswordReset showForgot={showForgotPassword} onClose={()=>setShowForgotPassword(false)}/>}
        <Container component="main" maxWidth="xs">
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" onClick={() => setShowForgotPassword(true)}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
        </>
    );
}

export default Login;
